// SidebarTopItems.js

import React from "react";
import { List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

const SidebarTopItems = ({
  open,
  items,
  location,
  navigate,
  isMobile,
  setMobileOpen,
  setProfile,
}) => {
  if (!items || items.length === 0) return null;

  return (
    <List>
      {items.map((item) => {
        const hasNestedChildren = item.children && item.children.length > 0;
        const isActive = location.pathname.includes(item.path);

        return (
          <React.Fragment key={item.title}>
            <ListItemButton
              sx={{
                minHeight: 36,
                justifyContent: open ? "initial" : "center",
                px: 3,
              }}
              onClick={() => {
                if (item.path) {
                  navigate(item.path);
                  if (isMobile) setMobileOpen(false);
                  setProfile(false);
                }
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: "20px",
                  justifyContent: "center",
                  marginRight: "10px",
                }}
              >
                {item.icon}
              </ListItemIcon>
              {open && (
                <ListItemText
                  disableTypography
                  primary={item.title}
                  sx={{
                    marginRight: "10px",
                    fontWeight: 300,
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    maxWidth: "200px",
                    color: isActive ? "blue" : "inherit", // example styling
                  }}
                />
              )}
            </ListItemButton>

            {/* If you want to show nested items if the user is in /community, for example */}
            {hasNestedChildren && open && location?.pathname.includes("community") && (
              <List sx={{ pl: 4 }}>
                {item.children.map((child) => (
                  <ListItemButton
                    key={child.title}
                    sx={{
                      minHeight: 36,
                      justifyContent: "initial",
                      px: 3,
                    }}
                    onClick={() => {
                      navigate(child.path);
                      if (isMobile) setMobileOpen(false);
                      setProfile(false);
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "20px",
                        justifyContent: "center",
                        marginRight: "10px",
                      }}
                    >
                      {child.icon}
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      primary={child.title}
                      sx={{
                        marginRight: "10px",
                        fontWeight: 300,
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "200px",
                      }}
                    />
                  </ListItemButton>
                ))}
              </List>
            )}
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default SidebarTopItems;
