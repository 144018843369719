import React, { useEffect, useState } from "react";
import {
  Grid,
  FormControl,
  Input,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import { API_URL, popupTimer } from "../../consts";
import { useDispatch } from "react-redux";
import ConfirmationDialog from "../common/ConfirmationDialog";
import TopicFilter from "../common/TopicFilter";
import HomeCardItem from "./HomeCardItem";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const CommunityRates = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [topTopics, setTopTopics] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [filterBy, setFilterBy] = useState(["You may love.."]);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [removedRateId, setRemovedRateId] = useState(null);
  const token = useSelector((state) => state.token);
  const setTokenInProcess = useSelector((state) => state.setTokenInProcess);

  let navigate = useNavigate();
  const filterRates = useSelector((state) => state.filterRates);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("yourItemKey");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);
  useEffect(() => {
    if (filterRates && filterRates !== null) {

      setData(filterRates)
    }

  }, [filterRates]);


  const deleteRate = () => {
    let url = API_URL + `/rates/${removedRateId}/delete`;
    axios
      .post(url, null)
      .then(() => {
        getRatesRequest(filterBy, selectedTopics);
        setTimeout(() => {
          dispatch({ type: "SET_POPUP", payload: { text: "", type: "" } });
        }, popupTimer);
        dispatch({
          type: "SET_POPUP",
          payload: { text: "Rate removed successfully", type: "success" },
        });
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          dispatch({ type: "SET_UNAUTHORIZED" });
          return;
        }
        dispatch({
          type: "SET_POPUP",
          payload: {
            text:
              err.response?.data?.error ||
              "Something Went Wrong. Please Try Again Later",
            type: "error",
          },
        });
        setTimeout(() => {
          dispatch({ type: "SET_POPUP", payload: { text: "", type: "" } });
        }, popupTimer);
      });
  };

  const getRatesRequest = (searchType, topics) => {
    let baseUrl = `/rates`;
    if (token) {
      baseUrl = `/rates/authenticated`;
    }
    let url = API_URL + baseUrl
    let paramsBody = {
      tags: null,
      topics: topics.length > 0 ? topics.join(",") : null,
    };
    axios
      .get(url, { params: paramsBody })
      .then((res) => {
        setData(res.data.rates);
        setTopTopics(res.data.top_topics);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          dispatch({ type: "SET_UNAUTHORIZED" });
          return;
        }
        dispatch({
          type: "SET_POPUP",
          payload: {
            text:
              err.response?.data?.error ||
              "Something Went Wrong. Please Try Again Later",
            type: "error",
          },
        });
        setTimeout(() => {
          dispatch({ type: "SET_POPUP", payload: { text: "", type: "" } });
        }, popupTimer);
      });
  };

  useEffect(() => {
    console.log('set in process', setTokenInProcess)
    if (!setTokenInProcess) {
      getRatesRequest(filterBy, selectedTopics);

    }
  }, [filterBy, selectedTopics, setTokenInProcess]);

  return (
    <Grid
      container
      spacing={2}
      style={{ paddingTop: "30px", width: "100%", overflowX: "hidden" }}
    >
      <Grid item xs={12}>
        <h2>Explore community </h2>
        <div style={{ maxWidth: '1100px' }}>

          <TopicFilter
            onSelect={(topic) => {
              setFilterBy(topic);
              setSelectedTopics([topic]);
              getRatesRequest(topic, [topic]);
            }}
            firstTopics={topTopics}
          />
        </div>
        <h3>{filterBy === "recommended" ? "You May Love..." : filterBy.join(", ")}</h3>

      </Grid>

      <div style={{ display: 'flex', flexWrap: 'wrap', padding: '16px', margin: '0 auto', marginRight: '30px' }}>

        {data.map((m, index) => (
          <Grid item xs={12} sm={2} md={4} key={index}>
            <div style={{ height: '400px', marginBottom: '10px', padding: '10px' }}>
              <HomeCardItem

                isBookmark={true}
                data={m}
                applyMargin={true}
                showRemove={true}
                onRemove={(id) => {
                  setRemoveDialogOpen(true);
                  setRemovedRateId(id);
                }}
                onRateSuccessfully
              />
            </div>

          </Grid>
        ))}
      </div>


      <ConfirmationDialog
        open={removeDialogOpen}
        onClose={() => setRemoveDialogOpen(false)}
        onConfirm={() => {
          deleteRate();
          setRemoveDialogOpen(false);
        }}
        title={"Delete rate"}
        cancelButtonString={"Cancel"}
        confirmButtonString={"Delete"}
        message={"Are you sure you want to delete this rate?"}
      />
    </Grid>
  );
};

export default CommunityRates;
