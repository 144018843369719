// DragAndDropActionDialog.js

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const DragAndDropActionDialog = ({
  open,
  setOpen,
  draggingSpace,
  dropDestinationSpace,
  onActionChoice,
}) => {
  const handleClose = () => setOpen(false);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {`Move "${draggingSpace?.name}" into "${dropDestinationSpace?.name}"?`}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Typography variant="body2" color="textSecondary">
          ⚠️ Merging will combine all contents and remove the origin space
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{ display: "flex", flexDirection: "column", gap: 1.5, padding: 3 }}
      >
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={() => onActionChoice("merge")}
        >
          Merge (Removes Origin)
        </Button>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={() => onActionChoice("subspace")}
        >
          Add as Sub-collection
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DragAndDropActionDialog;
