import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Grid, TextField, Autocomplete, CircularProgress } from "@mui/material";
import { API_URL, popupTimer } from "../../consts";
import axios from "axios";
import { useDispatch } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};

const CreateSpace = ({ open, handleClose, onSpaceAdded,isSubSpaceMode }) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    space_name: "",
    description: "",
    keywords: [],
  });
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);

  // -------------------------------------------------------------------------
  // New state for handling the list of all spaces, whether it’s loading,
  // and the selected parent space
  // -------------------------------------------------------------------------
  const [spaces, setSpaces] = useState([]);
  const [spacesLoading, setSpacesLoading] = useState(false);
  const [parentSpace, setParentSpace] = useState(null);

  // -------------------------------------------------------------------------
  // Fetch existing spaces on mount
  // -------------------------------------------------------------------------
  const getSpaces = () => {
    setSpacesLoading(true);
    const url = API_URL + "/spaces";
    axios
      .get(url)
      .then((res) => {
        setSpaces(res.data.spaces || []);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setSpacesLoading(false);
      });
  };

  useEffect(() => {
    getSpaces();
  }, []);

  // -------------------------------------------------------------------------
  // After spaces are fetched, check if current URL has a space ID,
  // and set that space as the default parent if it exists.
  // -------------------------------------------------------------------------
  useEffect(() => {
    if (!spacesLoading && spaces.length > 0) {
      // Example if not using React Router:
      const path = window.location.pathname; // e.g., "/spaces/OS5jsZQBGtUSPGykW5zC"
      const match = path.match(/\/spaces\/([^/]+)/);

      if (isSubSpaceMode && (match && match[1])) {
        const defaultParent = spaces.find((s) => s.id === match[1]);
        if (defaultParent) {
          setParentSpace(defaultParent);
        }
      }
    }
  }, [spacesLoading, spaces]);

  // -------------------------------------------------------------------------
  // Handle changes in the text fields
  // -------------------------------------------------------------------------
  const handleFormChange = (e) => {
    const { name, value } = e.target;

    if (name === "space_name") {
      const isNameTooLong = value.length > 64;
      setNameError(isNameTooLong);
      if (!isNameTooLong) {
        setForm({ ...form, [name]: value });
      }
    } else if (name === "description") {
      const isDescriptionTooLong = value.length > 128;
      setDescriptionError(isDescriptionTooLong);
      if (!isDescriptionTooLong) {
        setForm({ ...form, [name]: value });
      }
    } else {
      setForm({ ...form, [name]: value });
    }
    setSubmit(false);
  };

  // -------------------------------------------------------------------------
  // Submitting the form (including the optional parent_space_id)
  // -------------------------------------------------------------------------
  const handleFormSubmit = () => {
    setSubmit(true);
    const isValid =
      form.space_name &&
      form.space_name.length <= 64 &&
      (!form.description || form.description.length <= 256);

    if (isValid) {
      let url = API_URL + "/spaces";

      // Build the payload
      let payload = {
        ...form,
      };

      // If a parent space was selected, add parent_space_id
      if (parentSpace && parentSpace.id) {
        payload.parent_space_id = parentSpace.id;
      }

      axios
        .post(url, payload)
        .then(() => {
          setError(false);
          onSpaceAdded();
          setSubmit(false);
          setForm({
            space_name: "",
            description: "",
            keywords: [],
          });
          setParentSpace(null); // reset selection
          dispatch({
            type: "SET_POPUP",
            payload: {
              text: "New Space Added Successfully",
              type: "success",
            },
          });
          setTimeout(() => {
            dispatch({
              type: "SET_POPUP",
              payload: {
                text: "",
                type: "",
              },
            });
          }, popupTimer);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            dispatch({ type: "SET_UNAUTHORIZED" });
            return;
          }
          dispatch({
            type: "SET_POPUP",
            payload: {
              text: err?.response?.data?.error
                ? err.response.data.error
                : "Something Went Wrong. Please Try Again Later",
              type: "error",
            },
          });
          setTimeout(() => {
            dispatch({
              type: "SET_POPUP",
              payload: {
                text: "",
                type: "",
              },
            });
          }, popupTimer);
        });
    }
  };

  // -------------------------------------------------------------------------
  // Render
  // -------------------------------------------------------------------------
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box sx={style} className="signup-modal">
          <Typography id="transition-modal-title" variant="h6" component="h2">
            <CloseIcon
              className="cross-icon"
              onClick={() => {
                handleClose();
                setSubmit(false);
                setError(false);
                setNameError(false);
                setDescriptionError(false);
                setForm({
                  space_name: "",
                  description: "",
                  keywords: [],
                });
                setParentSpace(null);
              }}
            />
            <div className="card-heading mb-81"></div>
          </Typography>

          <Box id="transition-modal-description">
            <Typography
              id="transition-modal-title"
              variant="h2"
              component="h2"
              sx={{ fontSize: "20px", textAlign: "center", fontWeight: "700" }}
            >
              Add your new collection
            </Typography>

            <TextField
              name="space_name"
              hiddenLabel
              type="text"
              fullWidth
              className="loginTextfields"
              id="standard-basic"
              onChange={handleFormChange}
              value={form.space_name}
              label="Space Name"
              variant="standard"
              error={(submit && form.space_name === "") || nameError}
              helperText={
                submit && form.space_name === ""
                  ? "Field is Required"
                  : nameError
                    ? "Space name cannot exceed 64 characters"
                    : ""
              }
              sx={{
                marginTop: "20px",
                borderColor: nameError ? "red" : "",
                "& .MuiInput-underline:before": {
                  borderBottomColor: nameError ? "red" : "",
                },
              }}
            />

            <TextField
              name="description"
              hiddenLabel
              type="text"
              fullWidth
              className="loginTextfields"
              id="standard-basic"
              onChange={handleFormChange}
              value={form.description}
              label="Description"
              variant="standard"
              error={descriptionError}
              helperText={
                descriptionError
                  ? "Space description cannot exceed 128 characters"
                  : ""
              }
              sx={{
                marginTop: "20px",
                borderColor: descriptionError ? "red" : "",
                "& .MuiInput-underline:before": {
                  borderBottomColor: descriptionError ? "red" : "",
                },
              }}
            />

            {/* 
               Autocomplete for selecting a Parent Space (optional).
               Using 'loading' and a circular progress in InputProps
               so user sees a spinner until spaces are ready.
            */}
            <Autocomplete
              id="parent-spaces-autocomplete"
              options={spaces}
              loading={spacesLoading}
              value={parentSpace}
              onChange={(event, newValue) => {
                setParentSpace(newValue || null);
              }}
              getOptionLabel={(option) => option?.name || ""}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Parent Space (Optional)"
                  sx={{ marginTop: "20px" }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {spacesLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />

            <Autocomplete
              multiple
              freeSolo
              id="tags-outlined"
              options={[]}
              defaultValue={[]}
              value={form.keywords}
              clearOnBlur={true}
              onChange={(e, newval) => {
                setForm({ ...form, keywords: newval });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Add Keywords"
                  sx={{ marginTop: "20px" }}
                  onChange={() => setSubmit(false)}
                  onBlur={(e) => {
                    if (e.target.value !== "") {
                      setForm({
                        ...form,
                        keywords: form.keywords.concat(e.target.value),
                      });
                    }
                  }}
                />
              )}
            />
            <Typography paragraph sx={{ fontSize: "12px" }} className="textMuted">
              Each Tag must contain at least two characters.
            </Typography>

            <Grid container sx={{ margin: "0 auto" }}>
              <Grid item sx={{ margin: "0 auto" }}>
                <Button
                  className="connectButton"
                  onClick={handleFormSubmit}
                  disabled={
                    form.keywords.some((tag) => tag.length < 2) ||
                    nameError ||
                    descriptionError
                  }
                  variant="contained"
                >
                  Add collection
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CreateSpace;
