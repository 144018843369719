import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { API_URL, popupTimer } from "../../consts";

// Import sub-components
import TitleSection from "./TitleSection";
import LeftContent from "./LeftContent";
import RightInfoPanel from "./RightInfoPanel";
import DialogActionsSection from "./DialogActionsSection";

const HomeCardDialog = ({
  open,
  onClose,
  data,
  onReadMore,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [comments, setComments] = useState([]);
  const [showMoreComments, setShowMoreComments] = useState(10);

  const authorized = useSelector((state) => state.authorized);

  useEffect(() => {
    getComments();
    // eslint-disable-next-line
  }, [showMoreComments]);

  useEffect(() => {
    getComments();
    console.log(data.outer_html);
    // eslint-disable-next-line
  }, [data]);

  // Example: get comments from server
  const getComments = () => {
    const url = `${API_URL}/rates/${data.id}/marks?start_from=0&end_record=${showMoreComments}`;
    axios
      .get(url)
      .then((res) => {
        setComments(res.data);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch({ type: "SET_UNAUTHORIZED" });
          return;
        }
        dispatch({
          type: "SET_POPUP",
          payload: {
            text: err?.response?.data?.error
              ? err.response.data.error
              : "Something Went Wrong. Please Try Again Later",
            type: "error",
          },
        });
        setTimeout(() => {
          dispatch({
            type: "SET_POPUP",
            payload: {
              text: "",
              type: "",
            },
          });
        }, popupTimer);
      });
  };

  // Utility to extract domain
  const getDomain = (url) => {
    const parsedUrl = new URL(url);
    return parsedUrl.hostname;
  };

  // Handler for updating the rating (title or summary)
  const handleUpdate = async (updatedFields) => {
    try {
      // Example shape: { title: "New Title", comment: "New summary" }
      const response = await axios.put(
        `${API_URL}/rates/ratings/${data.id}`,
        {
          "stars": updatedFields.average_stars,
          "title": updatedFields.title,
          "comment": updatedFields.comment,

        }
      );
      // If successful, we may want to refresh data or merge in updates
      // For example, you can dispatch an action to update your store or do a local setState
      dispatch({
        type: "SET_POPUP",
        payload: {
          text: "Rating updated successfully!",
          type: "success",
        },
      });
      // Optionally update local 'data' here if you have it in state
      // or do a new GET for the single rating.

      setTimeout(() => {
        dispatch({ type: "SET_POPUP", payload: { text: "", type: "" } });
      }, popupTimer);
    } catch (err) {
      if (err?.response?.status === 401) {
        dispatch({ type: "SET_UNAUTHORIZED" });
        return;
      }
      dispatch({
        type: "SET_POPUP",
        payload: {
          text: err?.response?.data?.error
            ? err.response.data.error
            : "Failed to update rating. Try again later.",
          type: "error",
        },
      });
      setTimeout(() => {
        dispatch({ type: "SET_POPUP", payload: { text: "", type: "" } });
      }, popupTimer);
    }
  };

  // Close dialog
  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onClose(e);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      // Make the Dialog more responsive
      PaperProps={{
        sx: {
          width: { xs: "100vw", sm: "90vw" },
          height: { xs: "100vh", sm: "85%" },
          margin: { xs: 0, sm: "auto" },
          borderRadius: 2
        }
      }}
      sx={{
        "& .MuiDialog-container": {
          // On mobile, align to top for a full-screen feel
          alignItems: isMobile ? "flex-start" : "center",
        },
        "& .MuiDialog-paper": {
          width: "100vw",
          maxWidth: "100vw",
        },
      }}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
      }}
    >
      {/* Title Section */}
      <TitleSection
        title={data.title}
        url={data.page_id?.href}
        getDomain={getDomain}
        // Pass data & callback so TitleSection can update
        onUpdate={handleUpdate}
        authorized={authorized}
      />

      {/* Dialog Content */}
      <DialogContent
        sx={{
          // On mobile, fill the screen minus title & actions
          height: { xs: "calc(100vh - 130px)", sm: "calc(100% - 130px)" },
          p: 2,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            height: "100%",
            gap: "16px",
          }}
        >
          {/* Left (Image + Excerpt) */}
          <LeftContent data={data} onReadMore={onReadMore} />

          {/* Right (Info Panel) */}
          <RightInfoPanel
            data={data}
            onUpdate={handleUpdate}
            authorized={authorized}
          />
        </div>
      </DialogContent>

      {/* Dialog Actions */}
      <DialogActionsSection handleClose={handleClose} />
    </Dialog>
  );
};

export default HomeCardDialog;
