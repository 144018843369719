import React, { useRef, useState, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { API_URL } from "../../consts";
import { useSelector } from "react-redux";
import axios from 'axios';

const colorPalette = ['#FF6F61', '#6B5B95', '#88B04B', '#F7CAC9', '#92A8D1', '#955251', '#B565A7'];

const getPlaceholderColor = (name) => {
    const hash = Array.from(name)
        .reduce((acc, char) => acc + char.charCodeAt(0), 0);
    return colorPalette[hash % colorPalette.length];
};

const SpacesFilter = ({ onSelect, firstSpaces = [], searchValue = '' }) => {
    const containerRef = useRef(null);
    const [spaces, setSpaces] = useState([]);
    const [filteredSpaces, setFilteredSpaces] = useState([]);
    const [loading, setLoading] = useState(true);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);
    const [selectedSpaces, setSelectedSpaces] = useState([]);
    const token = useSelector((state) => state.token);

    useEffect(() => {
        fetchSpaces();
    }, [token]);

    useEffect(() => {
        if (searchValue) {
            const filtered = spaces.filter((space) =>
                space.name.toLowerCase().includes(searchValue.toLowerCase())
            );
            setFilteredSpaces(filtered);
        } else {
            setFilteredSpaces(spaces);
        }
    }, [searchValue, spaces]);

    const fetchSpaces = async () => {
        try {
            const url = `${API_URL}/spaces`;
            const response = await axios.get(url, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setSpaces(response.data.spaces || []);
            setFilteredSpaces(response.data.spaces || []);
        } catch (error) {
            console.error('Error fetching spaces:', error);
            setSpaces([]);
            setFilteredSpaces([]);
        } finally {
            setLoading(false);
            setTimeout(updateScrollState, 0); // Allow DOM updates before recalculating scroll
        }
    };

    const orderedSpaces = React.useMemo(() => {
        const prioritizedSpaces = firstSpaces
            .map((spaceName) => filteredSpaces.find((space) => space.name === spaceName))
            .filter(Boolean);
        const remainingSpaces = filteredSpaces.filter(
            (space) => !prioritizedSpaces.includes(space)
        );
        return [...prioritizedSpaces, ...remainingSpaces];
    }, [firstSpaces, filteredSpaces]);

    const updateScrollState = () => {
        if (containerRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
            setCanScrollLeft(scrollLeft > 0);
            setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
        }
    };

    useEffect(() => {
        const ref = containerRef.current;
        if (ref) {
            updateScrollState();
            ref.addEventListener('scroll', updateScrollState);
            return () => ref.removeEventListener('scroll', updateScrollState);
        }
    }, [filteredSpaces]);

    const scroll = (direction) => {
        if (containerRef.current) {
            const { scrollLeft, clientWidth } = containerRef.current;
            const scrollAmount = clientWidth / 2;
            containerRef.current.scrollTo({
                left: direction === 'left' ? scrollLeft - scrollAmount : scrollLeft + scrollAmount,
                behavior: 'smooth',
            });
        }
    };

    const toggleSpaceSelection = (spaceId) => {
        setSelectedSpaces((prevSelected) => {
            const updatedSelection = prevSelected.includes(spaceId)
                ? prevSelected.filter((id) => id !== spaceId)
                : [...prevSelected, spaceId];

            if (onSelect) {
                onSelect(updatedSelection); // Send IDs array
            }

            return updatedSelection;
        });
    };

    const getInitials = (name) => {
        const words = name.trim().split(' ');
        if (words.length > 1) {
            return (words[0][0] + words[1][0]).toUpperCase();
        }
        return name.slice(0, 2).toUpperCase();
    };

    if (loading) {
        return <div></div>;
    }

    return (
        <div
            style={{
                position: 'relative',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {canScrollLeft && (
                <div>
                    <FaChevronLeft
                        onClick={() => scroll('left')}
                        style={{
                            marginLeft: '10px',
                            fontSize: '24px',
                            cursor: 'pointer',
                            zIndex: 2,
                        }}
                    />
                </div>
            )}
            <div
                ref={containerRef}
                style={{
                    display: 'flex',
                    gap: '20px',
                    padding: '10px',
                    overflowX: 'auto',
                    flexGrow: 1,
                    maxWidth: '100%', // Prevents overflow
                    minWidth: 0, // Allows it to shrink within flex container

                    scrollbarWidth: 'none', // Hide scrollbar in Firefox
                }}
            >
                <style jsx>{`
                    div::-webkit-scrollbar {
                        display: none; // Hide scrollbar in Chrome, Edge, Safari
                    }
                `}</style>
                {orderedSpaces.map((space) => (
                    <div
                        key={space.id}
                        onClick={() => toggleSpaceSelection(space.id)}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            cursor: 'pointer',
                            border: selectedSpaces.includes(space.id) ? '2px solid rgb(0,202,153)' : '1px solid #ccc',
                            boxShadow: selectedSpaces.includes(space.id)
                                ? '0px 4px 10px rgba(0, 202, 153, 0.5)'
                                : 'none',
                            borderRadius: '10px',
                            padding: '10px',
                            width: '120px',
                            textAlign: 'center',
                            transition: 'transform 0.2s, box-shadow 0.2s',
                            flexShrink: 0,
                        }}
                        onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                        onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                    >
                        <div
                            style={{
                                marginBottom: '10px',
                                width: '60px',
                                height: '60px',
                                backgroundImage: space.image_url
                                    ? `url(${space.image_url})`
                                    : 'none',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                borderRadius: '50%',
                                backgroundColor: space.image_url
                                    ? 'transparent'
                                    : getPlaceholderColor(space.name),
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: '#fff',
                                fontSize: '18px',
                                fontWeight: 'bold',
                            }}
                        >
                            {!space.image_url && getInitials(space.name)}
                        </div>
                        <span style={{ fontWeight: 'bold' }}>{space.name}</span>
                    </div>
                ))}
            </div>
            {canScrollRight && (
                <div style={{ width: '5%' }}>
                    <FaChevronRight
                        onClick={() => scroll('right')}
                        style={{
                            marginRight: '10px',
                            fontSize: '24px',
                            cursor: 'pointer',
                            zIndex: 2,
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default SpacesFilter;
