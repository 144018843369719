import React, { useState, useEffect } from "react";
import {
  DialogTitle,
  Box,
  IconButton,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";

const TitleSection = ({ title, url, getDomain, onUpdate, authorized }) => {
  const [editMode, setEditMode] = useState(false);
  const [tempTitle, setTempTitle] = useState(title);

  // Keep local title in sync if parent passes in new title
  useEffect(() => {
    setTempTitle(title);
  }, [title]);

  // Save handler
  const handleSave = () => {
    if (onUpdate) {
      onUpdate({ title: tempTitle }); // send the updated title
    }
    setEditMode(false);
  };

  // Cancel handler
  const handleCancel = () => {
    setTempTitle(title);
    setEditMode(false);
  };

  return (
    <DialogTitle
      sx={{
        textAlign: "center",
        py: 2,
      }}
    >
      {/* Title area: either editing mode or read-only display */}
      {editMode ? (
        <Box sx={{ display: "inline-flex", alignItems: "center" }}>
          <TextField
            variant="outlined"
            size="small"
            value={tempTitle}
            onChange={(e) => setTempTitle(e.target.value)}
            sx={{ marginRight: 1, width: 200 }}
          />
          <IconButton onClick={handleSave} size="small">
            <SaveIcon />
          </IconButton>
          <IconButton onClick={handleCancel} size="small">
            <ClearIcon />
          </IconButton>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            fontWeight: "bold",
            fontSize: { xs: "1.2rem", sm: "1.5rem" },
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {title}
          {authorized && (
            <IconButton
              size="small"
              onClick={() => setEditMode(true)}
              sx={{ ml: 1 }}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      )}

      {/* Domain link below title */}
      {url && (
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            fontSize: { xs: "0.75rem", sm: "0.875rem" },
            color: "text.secondary",
            mt: 0.5,
          }}
        >
          <span
            onClick={() => window.open(url, "_blank", "noopener,noreferrer")}
            style={{
              cursor: "pointer",
              textDecoration: "none",
              color: "inherit",
            }}
          >
            {getDomain(url)}
          </span>
        </Box>
      )}
    </DialogTitle>
  );
};

export default TitleSection;
