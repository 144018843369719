import React, { useState, useEffect } from "react";
import {
  Box,
  Rating,
  useTheme,
  useMediaQuery,
  TextField,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";

const RightInfoPanel = ({ data, onUpdate, authorized }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [editMode, setEditMode] = useState(false);
  const [tempSummary, setTempSummary] = useState(data.comment || "");
  const [tempRating, setTempRating] = useState(data?.average_stars || 0);

  // Sync local state if parent data changes
  useEffect(() => {
    setTempSummary(data.comment || "");
    setTempRating(data?.average_stars || 0);
  }, [data.comment, data.average_stars]);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSave = () => {
    onUpdate({ comment: tempSummary, average_stars: tempRating });
    setEditMode(false);
  };

  const handleCancel = () => {
    setTempSummary(data.comment || "");
    setTempRating(data?.average_stars || 0);
    setEditMode(false);
  };

  return (
    <Box
      sx={{
        width: { xs: "100%", sm: "30%" },
        mt: { xs: 2, sm: 0 },
        height: "100%",
        background: "linear-gradient(to right, #e7ebe4, #fcf9f9)",
        borderRadius: 2,
        p: 3,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      {/* Rating + Title Bar for editing */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {editMode ? (
          // Editable rating
          <Rating
            name="editable-rating"
            value={tempRating}
            onChange={(e, newValue) => setTempRating(newValue)}
            size="large"
            emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
            sx={{ color: "rgba(255, 204, 72, 1)" }}
          />
        ) : (
          // Read-only rating
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Rating
              value={data?.average_stars || 0}
              readOnly
              size="large"
              emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
              sx={{ color: "rgba(255, 204, 72, 1)" }}
            />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                {data?.average_stars || 0}
                <span style={{ color: "grey" }}> / 5</span>
              </Typography>
            </Box>
          </Box>
        )}

        {/* Edit / Save / Cancel Icons */}
        {authorized && (
          <Box>
            {!editMode ? (
              <IconButton onClick={handleEdit} size="medium">
                <EditIcon />
              </IconButton>
            ) : (
              <Stack direction="row" spacing={1}>
                <IconButton onClick={handleSave} color="success" size="medium">
                  <SaveIcon />
                </IconButton>
                <IconButton onClick={handleCancel} color="inherit" size="medium">
                  <ClearIcon />
                </IconButton>
              </Stack>
            )}
          </Box>
        )}
      </Box>

      {/* Price */}
      {data?.price && (
        <Box>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
            Price
          </Typography>
          <Typography variant="body2" sx={{ fontStyle: "italic", opacity: 0.8 }}>
            {data.currency
              ? `${data.price}${data.currency}`
              : data.price_label}
          </Typography>
        </Box>
      )}

      {/* Summary */}
      <Box>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
          Summary
        </Typography>
        {editMode ? (
          <TextField
            multiline
            rows={4}
            fullWidth
            value={tempSummary}
            onChange={(e) => setTempSummary(e.target.value)}
            sx={{
              mb: 1,
              backgroundColor: "#fff",
              borderRadius: 1,
              "& .MuiOutlinedInput-root": {
                fontSize: "0.95rem",
              },
            }}
          />
        ) : (
          <Box
            sx={{
              border: "1px solid #e0e0e0",
              borderRadius: 1,
              p: 2,
              minHeight: 100,
              fontSize: "0.95rem",
            }}
          >
            {data.comment}
          </Box>
        )}
      </Box>

      {/* Tags */}
      {data?.tags?.length > 0 && (
        <Box>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
            Tags
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {data.tags.map((tag, index) => (
              <Typography
                key={index}
                sx={{
                  backgroundColor: "#e0e0e0",
                  px: 1,
                  py: 0.5,
                  borderRadius: "16px",
                  fontSize: "0.85rem",
                }}
              >
                {tag}
              </Typography>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default RightInfoPanel;
