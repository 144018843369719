import React from "react";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import { Toolbar } from "@mui/material";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import HomeIcon from "@mui/icons-material/Home";
import Star from "@mui/icons-material/Star";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { useLocation } from "react-router";
import { useNavigate } from "react-router";
import {QueryStats} from "@mui/icons-material";

const sidebarItems = [
  { title: "Home", icon: <HomeIcon />, path: "/" },
  { title: "My Reviews", icon: <Star />, path: "/my-reviews" },
  // { title: "My Reviews", icon: <ReviewsIcon />, path: "/" },
  // { title: "Bookmarks", icon: <BookmarkIcon />, path: "/bookmarks" },
  // { title: "Private Data", icon: <LockIcon />, path: "/private" },
  {
      title: "Trends & Analytics",
      icon: <QueryStats/>,
      path: "/trends",
  },
  {
    title: "My Websites",
    icon: <BusinessCenterIcon />,
    path: "/my-websites",
  }
];

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

const Sidebar = ({ setProfile, componentChange }) => {
  const location = useLocation();
  const navigate = useNavigate();
  React.useEffect(() => {
    componentChange();
  }, [location]);
  return (
    <div>
      <Toolbar sx={{ marginTop: "16px" }} />
      <List>
        {sidebarItems.map((item, index) => (
          <ListItem
            classes={{
              primary: {
                fontSize: "12px",
                fontWeight: "500",
              },
            }}
            button
            className={classNames(
              item.path === location.pathname
                ? "highlightedSidebar"
                : "sidebarItem"
            )}
            key={index}
            onClick={() => {
              navigate(item.path);
              setProfile(false);
            }}
            sx={{
              width: "auto",
              margin: { xs: "10px 30px", sm: "10px 0px" },
            }}
          >
            <ListItemIcon
              sx={{ minWidth: "auto", mr: 3 }}
              className={
                item.path === location.pathname ? "iconColor" : "sidebarItem"
              }
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText
              disableTypography
              className={classNames(
                item.path === location.pathname
                  ? "sidebarItemHigh"
                  : "sidebarItem"
              )}
              primary={item.title}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
};
export default Sidebar;
