import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import PublisherComment from "./PublisherComment";
import ChipList from "../common/ChipList";

const HomeCardBody = ({ data, searhTags }) => {
  return (
    <>

      {(data.content ||data.title)&& (
        <Box sx={{ width: "100%" }}>
          <PublisherComment comment={data.title||data.content} onClickDisabled />
        </Box>
      )}

      <div style={{ paddingTop: "5px" }}>
        <ChipList list={data.tags} highlightList={searhTags} AllowChipOverflow />
      </div>
    </>
  );
};

export default HomeCardBody;
