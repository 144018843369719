// SpaceList.jsx
import React from "react";
import {
  List,
  ListItemButton,
  Box,
  IconButton,
  InputBase,
  Collapse,
  Tooltip,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import AddIcon from "@mui/icons-material/Add";

const SpaceList = ({
  spaces,
  open,
  location,
  navigate,
  isMobile,
  setMobileOpen,
  setProfile,
  expandedSpaces,
  handleSpaceToggle,
  searchQuery,
  draggingSpaceId,
  setDraggingSpaceId,
  draggingSpace,
  setDraggingSpace,
  dragInProcess,
  setDragInProcess,
  dropDestinationId,
  setDropDestinationId,
  dropDestinationSpace,
  setDropDestinationSpace,
  setShowActionDialog,
  handleCollaboratorsOpen,
  setIsCreateSpaceOpen,
  setIsSubSpaceMode,
  // New prop to disable or enable drag-and-drop
  disableDrag = false,
}) => {
  // Recursively filter spaces
  const filterSpaces = (spaceList, query) => {
    return spaceList
      .map((space) => {
        const filteredChildren = filterSpaces(space.childrens || [], query);
        const nameMatches = space.name
          .toLowerCase()
          .includes(query.toLowerCase());
        if (nameMatches || filteredChildren.length > 0) {
          return { ...space, childrens: filteredChildren };
        }
        return null;
      })
      .filter(Boolean);
  };

  // Apply filter
  const filteredSpaces = React.useMemo(() => {
    if (!searchQuery.trim()) return spaces;
    return filterSpaces(spaces, searchQuery.trim());
  }, [spaces, searchQuery]);

  // DRAG & DROP EVENT HANDLERS
  const handleDragStart = (e, space) => {
    setDragInProcess(true);
    e.stopPropagation();
    setDraggingSpaceId(space.id);
    setDraggingSpace(space);

    // Custom drag image
    e.dataTransfer.effectAllowed = "move";
    const dragGhost = document.createElement("div");
    dragGhost.textContent = space.name;
    dragGhost.style.position = "absolute";
    dragGhost.style.top = "-9999px";
    dragGhost.style.left = "-9999px";
    dragGhost.style.fontSize = "14px";
    dragGhost.style.fontWeight = "bold";
    dragGhost.style.padding = "4px 8px";
    dragGhost.style.borderRadius = "4px";
    dragGhost.style.backgroundColor = "#fff";
    dragGhost.style.border = "1px solid #ccc";
    dragGhost.style.boxShadow = "0px 2px 5px rgba(0,0,0,0.15)";
    dragGhost.style.pointerEvents = "none";

    document.body.appendChild(dragGhost);
    e.dataTransfer.setDragImage(dragGhost, 0, 0);

    setTimeout(() => {
      document.body.removeChild(dragGhost);
    }, 0);
  };

  // When dragging over a valid drop target
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  // When dropping onto a valid target
  const handleDrop = (e, space) => {
    setDragInProcess(false);
    e.preventDefault();
    e.stopPropagation();
    if (draggingSpaceId === space.id) return;
    setDropDestinationId(space.id);
    setDropDestinationSpace(space);
    setShowActionDialog(true);
  };

  // If dragging ends anywhere (even outside valid drop zone), reset drag state
  const handleDragEnd = (e) => {
    setDragInProcess(false);
    e.stopPropagation();
  };

  // Render spaces recursively
  const renderSpaces = (spaceList, level = 0) => {
    return spaceList.map((space) => {
      const hasChildren = space.childrens && space.childrens.length > 0;
      const isExpanded = !!expandedSpaces[space.id];
      const isActive = location.pathname.includes(`/spaces/${space.id}`);

      return (
        <React.Fragment key={space.id}>
          <ListItemButton
            style={{
              backgroundColor:
                !disableDrag && dragInProcess ? "rgb(181 242 227)" : "",
            }}
            draggable={!disableDrag}
            // Attach event handlers only if drag is enabled
            onDragStart={disableDrag ? undefined : (e) => handleDragStart(e, space)}
            onDragOver={disableDrag ? undefined : handleDragOver}
            onDrop={disableDrag ? undefined : (e) => handleDrop(e, space)}
            onDragEnd={disableDrag ? undefined : handleDragEnd} // <--- IMPORTANT
            sx={{
              justifyContent: open ? "initial" : "center",
              pl: 4 + level * 2,
              position: "relative",
            }}
            onClick={() => {
              navigate(`/spaces/${space.id}`);
              if (isMobile) setMobileOpen(false);
              setProfile(false);
            }}
          >
            {hasChildren && open && (
              <IconButton
                size="small"
                onClick={(e) => handleSpaceToggle(e, space.id)}
                sx={{ marginRight: "8px" }}
              >
                {isExpanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            )}
            {!hasChildren && open && (
              <Box sx={{ width: 24, marginRight: "8px" }} />
            )}

            {open && (
              <span
                className={isActive ? "sidebarItemHigh" : "sidebarItem"}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  display: "inline-block",
                  maxWidth: "calc(100% - 5px)",
                }}
                title={space.name}
              >
                {space.name}
              </span>
            )}

            {/* If this is the currently viewed space, show Settings and Add sub-collection */}
            {isActive && (
              <div
                style={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Tooltip title="Settings" arrow>
                  <SettingsOutlined
                    style={{
                      fontSize: 18,
                      color: "rgba(0, 0, 0, 0.6)",
                      cursor: "pointer",
                      margin: "5px",
                      marginTop: "10px",
                    }}
                    onClick={(e) => handleCollaboratorsOpen(e, space)}
                  />
                </Tooltip>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsCreateSpaceOpen(true);
                    setIsSubSpaceMode(true);
                  }}
                >
                  <AddIcon sx={{ fontSize: 20, color: "rgba(0, 0, 0, 0.6)" }} />
                </IconButton>
              </div>
            )}
          </ListItemButton>

          {hasChildren && (
            <Collapse in={isExpanded && open} timeout="auto" unmountOnExit>
              <List disablePadding>
                {renderSpaces(space.childrens, level + 1)}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <Box>
      {/* Search + Add (for top-level) */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          mb: 1,
          mt: 1,
        }}
      >
        <Box
          sx={{
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            padding: "4px 8px",
            boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
            paddingLeft: "43px",
            marginLeft: "20px",
          }}
        >
          <InputBase
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => {
              // local search logic if needed
            }}
            sx={{
              fontSize: "14px",
              width: "100%",
              color: "#333",
            }}
          />
        </Box>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setIsCreateSpaceOpen(true);
            setIsSubSpaceMode(false);
          }}
        >
          <AddIcon sx={{ fontSize: 20, color: "rgba(0, 0, 0, 0.6)" }} />
        </IconButton>
      </Box>

      {/* The actual list of spaces */}
      <Box
        sx={{
          maxHeight: "300px",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "6px",
          },
          "&::-webkit-scrollbar-track": {
            background: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          scrollbarWidth: "thin",
          scrollbarColor: "rgba(0, 0, 0, 0.3) transparent",
        }}
      >
        <List disablePadding>{renderSpaces(filteredSpaces)}</List>
      </Box>
    </Box>
  );
};

export default SpaceList;
