import React, { useState, useEffect } from 'react';
import { IconButton, Button, TextField, Typography, Box } from '@mui/material';
import { DeleteOutline, CancelOutlined, SaveOutlined, ImageOutlined, VideoLibraryOutlined, UploadOutlined, EditOutlined } from '@mui/icons-material';
import CropIcon from '@mui/icons-material/Crop';

const ImageUploaderAndCrop = ({
    isDragging,
    handleDrop,
    handleDragOver,
    handleDragLeave,
    displayedImage,
    stagedImage,
    stagedImageFile,
    handleCancelImage,
    handleSaveImage,
    handleCropAgain,
    handleFileInput,
    onVideoSave,
    disabled,
    defaultVideoUrl,
    onRemoveImage,
    isEditing
}) => {
    const [mode, setMode] = useState('image');
    const [videoUrl, setVideoUrl] = useState('');
    const [isEditingVideo, setIsEditingVideo] = useState(false);
    const [tempVideoUrl, setTempVideoUrl] = useState('');

    useEffect(() => {
        if (defaultVideoUrl) {
            setVideoUrl(defaultVideoUrl);
            setTempVideoUrl(defaultVideoUrl);
            setMode('video');
        }
    }, [defaultVideoUrl]);

    const handleModeChange = (newMode) => {
        setMode(newMode);
    };

    const handleVideoSave = () => {
        const urlToSave = isEditingVideo ? tempVideoUrl.trim() : videoUrl.trim();
        onVideoSave(urlToSave);
        setVideoUrl(urlToSave);
        setIsEditingVideo(false);
    };

    const handleVideoCancel = () => {
        setTempVideoUrl(videoUrl);
        setIsEditingVideo(false);
    };

    const handleEditStart = () => {
        setTempVideoUrl(videoUrl);
        setIsEditingVideo(true);
    };

    const isYouTubeUrl = (url) => {
        const youtubeRegex = /^(https?:\/\/(www\.)?(youtube\.com|youtu\.be)\/).+/;
        return youtubeRegex.test(url);
    };

    const getYouTubeEmbedUrl = (url) => {
        if (url.includes('youtu.be')) {
            return `https://www.youtube.com/embed/${url.split('/').pop()}`;
        }
        return `https://www.youtube.com/embed/${url.split('v=')[1]?.split('&')[0]}`;
    };

    return (
        <Box
            sx={{
                width: '180px',
                position: 'relative',
                padding: '10px',
                borderRadius: '8px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                backgroundColor: '#f9f9f9',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '16px',
                }}
            >
                {/* <Button
                    startIcon={<ImageOutlined />}
                    variant={mode === 'image' ? 'contained' : 'outlined'}
                    onClick={() => handleModeChange('image')}
                    disabled={disabled}
                    sx={{ flex: 1, marginRight: '8px' }}
                >
                    Image
                </Button> */}
                {/* <Button
                    startIcon={<VideoLibraryOutlined />}
                    variant={mode === 'video' ? 'contained' : 'outlined'}
                    onClick={() => handleModeChange('video')}
                    disabled={disabled}
                    sx={{ flex: 1 }}
                >
                    Video URL
                </Button> */}
            </Box>

            {/* {mode === 'video' && (
                <Box sx={{ width: '100%' }}>
                    <Box sx={{
                        width: '100%',
                        height: '120px',
                        marginBottom: '16px',
                        borderRadius: '8px',
                        overflow: 'hidden',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        position: 'relative',
                        backgroundColor: '#f3f4f6',
                    }}>
                        {videoUrl && isYouTubeUrl(videoUrl) ? (
                            <iframe
                                width="100%"
                                height="100%"
                                src={getYouTubeEmbedUrl(videoUrl)}
                                title="YouTube video preview"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        ) : (
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                color: '#9ca3af',
                            }}>
                                <VideoLibraryOutlined sx={{ fontSize: 48, mb: 1 }} />
                                <Typography variant="body2" color="textSecondary">
                                    {videoUrl ? 'Invalid YouTube URL' : 'No video selected'}
                                </Typography>
                            </Box>
                        )}


                    </Box>

                    <Box sx={{ mt: 2 }}>
                        <TextField
                            label="YouTube Video URL"
                            variant="outlined"
                            fullWidth
                            value={isEditingVideo ? tempVideoUrl : videoUrl}
                            onChange={(e) => isEditingVideo ? setTempVideoUrl(e.target.value) : setVideoUrl(e.target.value)}
                            disabled={disabled}
                            sx={{ mb: 2 }}
                        />
                        <Box sx={{
                            display: 'flex',
                            gap: '8px',
                        }}>
                            <Button
                                startIcon={<SaveOutlined />}
                                variant="contained"
                                color="primary"
                                onClick={handleVideoSave}
                                disabled={disabled || videoUrl === defaultVideoUrl}
                                fullWidth
                            >
                                Save
                            </Button>
                            {isEditingVideo && (
                                <Button
                                    startIcon={<CancelOutlined />}
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleVideoCancel}
                                    disabled={disabled}
                                    fullWidth
                                >
                                    Cancel
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Box>
            )} */}

            {(
                <Box
                    sx={{
                        width: '150px',
                        height: '130px',
                        border: `2px dashed ${isDragging ? '#1976d2' : '#e0e3e7'}`,
                        borderRadius: '8px',
                        position: 'relative',
                        transition: 'all 0.2s ease',
                        backgroundColor: !disabled
                            ? 'rgb(245, 245, 245)'
                            : '#e0e0e0',
                        cursor: !disabled ? 'pointer' : 'auto',
                        overflow: 'hidden',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    onDrop={!disabled ? handleDrop : undefined}
                    onDragOver={!disabled ? handleDragOver : undefined}
                    onDragLeave={!disabled ? handleDragLeave : undefined}
                >

                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            padding: '8px',
                            background: 'rgb(255 255 255 / 50%)',
                            borderRadius: '0 0 0 8px',
                            zIndex: 2,
                        }}
                    >
                        {!stagedImageFile && <IconButton
                            size="small"
                            sx={{ color: '#fff' }}
                            onClick={onRemoveImage} // Call the onRemoveImage function
                            title="Remove image"
                        >
                            <DeleteOutline />
                        </IconButton>}
                    </Box>
                    {displayedImage ? (
                        <>
                            <img
                                src={displayedImage}
                                alt="Uploaded"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderRadius: '6px',
                                    display: 'block',
                                }}
                            />

                            {displayedImage && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        padding: '8px',
                                        background: 'rgb(255 255 255 / 50%)',
                                        borderRadius: '0 0 0 6px',
                                        display: 'flex',
                                        gap: '4px',
                                        zIndex: 2,
                                    }}
                                >
                                
                                    {isEditing && (
                                        <>
                                            <IconButton
                                                size="small"
                                                sx={{ color: '#fff' }}
                                                onClick={handleCancelImage}
                                                title="Cancel the staged image"
                                            >
                                                <CancelOutlined />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                sx={{ color: '#fff' }}
                                                onClick={handleSaveImage}
                                                title="Save the new image"
                                            >
                                                <SaveOutlined />
                                            </IconButton>
                                        </>
                                    )}

                                    {isEditing && (
                                        <IconButton
                                            size="small"
                                            sx={{ color: '#fff' }}
                                            onClick={handleCropAgain}
                                            title="Crop again"
                                        >
                                            <CropIcon />
                                        </IconButton>
                                    )}
                                </Box>
                            )}
                        </>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                padding: '16px',
                                textAlign: 'center',
                            }}
                        >
                            <UploadOutlined
                                sx={{
                                    fontSize: '48px',
                                    color: '#9ca3af',
                                    marginBottom: '8px',
                                }}
                            />
                            <Typography
                                variant="body2"
                                sx={{ color: '#6b7280' }}
                            >
                                Drop image here or click to upload
                                <br />
                                (png/jpeg/jpg)
                            </Typography>
                        </Box>
                    )}

                    <input
                        id="imageUpload"
                        type="file"
                        accept="image/*"
                        onChange={!disabled ? handleFileInput : undefined}
                        style={{
                            position: 'absolute',
                            inset: 0,
                            width: '100%',
                            height: '100%',
                            opacity: 0,
                            cursor: !disabled ? 'pointer' : 'auto',
                        }}
                        disabled={disabled}
                    />
                </Box>
            )}
        </Box>
    );
};

export default ImageUploaderAndCrop;