import React, { useRef, useState, useEffect } from 'react';
import {
    FaChevronLeft,
    FaChevronRight,
    FaNewspaper,
    FaLaptop,
    FaTree,
    FaHeartbeat,
    FaRocket,
    FaDollarSign,
    FaGraduationCap,
    FaRunning,
    FaFilm,
    FaPaintBrush,
    FaUtensils,
    FaBriefcase,
    FaBolt,
    FaPlane,
    FaHome,
    FaAppleAlt,
    FaFlask,
    FaTshirt,
    FaBaby,
    FaHashtag,
    FaLightbulb,
    FaShoppingCart,
    FaGamepad,
    FaBalanceScale,
    FaFutbol,
    FaTableTennis,
    FaBaseballBall,
    FaFootballBall,
    FaStar
} from 'react-icons/fa';

const topics = [
    { id: 1, name: 'News', icon: <FaNewspaper /> },
    { id: 2, name: 'Technology', icon: <FaLaptop /> },
    { id: 3, name: 'Environment', icon: <FaTree /> },
    { id: 4, name: 'Health', icon: <FaHeartbeat /> },
    { id: 5, name: 'Space', icon: <FaRocket /> },
    { id: 6, name: 'Finance', icon: <FaDollarSign /> },
    { id: 7, name: 'Education', icon: <FaGraduationCap /> },
    { id: 8, name: 'Sports', icon: <FaRunning /> },
    { id: 9, name: 'Entertainment', icon: <FaFilm /> },
    { id: 10, name: 'Culture', icon: <FaPaintBrush /> },
    { id: 11, name: 'Cooking', icon: <FaUtensils /> },
    { id: 12, name: 'Business', icon: <FaBriefcase /> },
    { id: 13, name: 'Energy', icon: <FaBolt /> },
    { id: 14, name: 'Travel', icon: <FaPlane /> },
    { id: 15, name: 'Home', icon: <FaHome /> },
    { id: 16, name: 'Food', icon: <FaAppleAlt /> },
    { id: 17, name: 'Science', icon: <FaFlask /> },
    { id: 18, name: 'Fashion', icon: <FaTshirt /> },
    { id: 19, name: 'Parenting', icon: <FaBaby /> },
    { id: 20, name: 'Social Media', icon: <FaHashtag /> },
    { id: 21, name: 'Innovation', icon: <FaLightbulb /> },
    { id: 22, name: 'Shopping', icon: <FaShoppingCart /> },
    { id: 23, name: 'Gaming', icon: <FaGamepad /> },
    { id: 24, name: 'Politics', icon: <FaBalanceScale /> },
    { id: 25, name: 'Soccer', icon: <FaFutbol /> },
    { id: 26, name: 'Tennis', icon: <FaTableTennis /> },
    { id: 27, name: 'Baseball', icon: <FaBaseballBall /> },
    { id: 28, name: 'Football', icon: <FaFootballBall /> },
];

const TopicFilter = ({ onSelect, firstTopics = [] }) => {
    const containerRef = useRef(null);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);
    const [selectedTopics, setSelectedTopics] = useState([]);

    const orderedTopics = React.useMemo(() => {
        const prioritizedTopics = firstTopics
            .map((topicName) => topics.find((topic) => topic.name === topicName))
            .filter(Boolean);
        const remainingTopics = topics.filter(
            (topic) => !prioritizedTopics.includes(topic)
        );
        return [...prioritizedTopics, ...remainingTopics];
    }, [firstTopics]);

    const updateScrollState = () => {
        if (containerRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
            setCanScrollLeft(scrollLeft > 0);
            setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
        }
    };

    useEffect(() => {
        updateScrollState();
        const ref = containerRef.current;
        if (ref) {
            ref.addEventListener('scroll', updateScrollState);
        }
        return () => {
            if (ref) {
                ref.removeEventListener('scroll', updateScrollState);
            }
        };
    }, []);

    const scroll = (direction) => {
        if (containerRef.current) {
            const { scrollLeft, clientWidth } = containerRef.current;
            const scrollAmount = clientWidth / 2;
            containerRef.current.scrollTo({
                left: direction === 'left' ? scrollLeft - scrollAmount : scrollLeft + scrollAmount,
                behavior: 'smooth',
            });
        }
    };

    const toggleTopicSelection = (topicName) => {
        setSelectedTopics((prevSelected) => {
            const updatedSelection = prevSelected.includes(topicName)
                ? prevSelected.filter((name) => name !== topicName)
                : [...prevSelected, topicName];

            if (onSelect) {
                onSelect(updatedSelection);
            }

            return updatedSelection;
        });
    };

    return (
        <div
            style={{
                position: 'relative',
                width: '100%',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {canScrollLeft && (
                <div style={{ width: '5%' }}>
                    <FaChevronLeft
                        onClick={() => scroll('left')}
                        style={{
                            marginLeft: '10px',
                            fontSize: '24px',
                            cursor: 'pointer',
                            zIndex: 2,
                        }}
                    />
                </div>
            )}
            <div
                ref={containerRef}
                style={{
                    display: 'flex',
                    gap: '20px',
                    padding: '10px',
                    overflow: 'hidden',
                    flexGrow: 1,
                    maxWidth: '100%', // Prevents overflow
                    minWidth: 0, // Allows it to shrink within flex container
                }}
            >
                <div
                    key={'You may love..'}
                    onClick={() => toggleTopicSelection('You may love..')}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        cursor: 'pointer',
                        border: selectedTopics.includes('You may love..') ? '2px solid rgb(0,202,153)' : '1px solid #ccc',
                        boxShadow: selectedTopics.includes('You may love..')
                            ? '0px 4px 10px rgba(0, 202, 153, 0.5)'
                            : 'none',
                        borderRadius: '10px',
                        padding: '10px',
                        width: '120px',
                        textAlign: 'center',
                        transition: 'transform 0.2s, box-shadow 0.2s',
                        flexShrink: 0,
                    }}
                    onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                    onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                >
                    <div style={{ fontSize: '48px', marginBottom: '10px', color: 'rgb(0,202,153)' }}>
                        <FaStar />
                    </div>
                    <span style={{ fontWeight: 'bold' }}>You may love..</span>
                </div>
                {orderedTopics.map((topic) => (
                    <div
                        key={topic.id}
                        onClick={() => toggleTopicSelection(topic.name)}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            cursor: 'pointer',
                            border: selectedTopics.includes(topic.name) ? '2px solid rgb(0,202,153)' : '1px solid #ccc',
                            boxShadow: selectedTopics.includes(topic.name)
                                ? '0px 4px 10px rgba(0, 202, 153, 0.5)'
                                : 'none',
                            borderRadius: '10px',
                            padding: '10px',
                            width: '120px',
                            textAlign: 'center',
                            transition: 'transform 0.2s, box-shadow 0.2s',
                            flexShrink: 0,
                        }}
                        onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                        onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                    >
                        <div style={{ fontSize: '48px', marginBottom: '10px', color: 'rgb(0,202,153)' }}>
                            {topic.icon}
                        </div>
                        <span style={{ fontWeight: 'bold' }}>{topic.name}</span>
                    </div>
                ))}
            </div>
            {canScrollRight && (
                <div style={{ width: '5%' }}>
                    <FaChevronRight
                        onClick={() => scroll('right')}
                        style={{
                            marginRight: '10px',
                            fontSize: '24px',
                            cursor: 'pointer',
                            zIndex: 2,
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default TopicFilter;
