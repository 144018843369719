// MiniSidebar.js

import React from "react";
import { IconButton, useMediaQuery, Dialog } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import SidebarDrawer from "./SidebarDrawer";
import SidebarTopItems from "./SidebarTopItems";
import SidebarCollapsibleSection from "./SidebarCollapsibleSection";
import SpaceList from "./SpaceList";
import DragAndDropActionDialog from "./DragAndDropActionDialog";
import CreateSpace from "../Space/CreateSpace";
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import StarOutline from '@mui/icons-material/StarOutline';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';

import { API_URL } from "../../consts";

// -------------- constants and defaults ---------------
const drawerWidth = 300;

const MiniSidebar = ({ setProfile, onOpenChange }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // -------------- local states ---------------
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(
    localStorage.getItem("dontMinimize") === "true"
  );
  const [dontMinimize, setDontMinimize] = React.useState(
    localStorage.getItem("dontMinimize") === "true"
  );

  // -------------- data states ---------------
  const [mySpaces, setMySpaces] = React.useState([]);
  const [sharedSpaces, setSharedSpaces] = React.useState([]);

  // -------------- collapsible sections ---------------
  const [myCollectionsOpen, setMyCollectionsOpen] = React.useState(false);
  const [sharedCollectionsOpen, setSharedCollectionsOpen] = React.useState(false);

  // -------------- search ---------------
  const [searchQuery, setSearchQuery] = React.useState("");

  // -------------- create space dialog ---------------
  const [isCreateSpaceOpen, setIsCreateSpaceOpen] = React.useState(false);
  const [isSubSpaceMode, setIsSubSpaceMode] = React.useState(false);

  // -------------- drag and drop states ---------------
  const [draggingSpaceId, setDraggingSpaceId] = React.useState(null);
  const [dragInProcess, setDragInProcess] = React.useState(false);
  const [dropDestinationId, setDropDestinationId] = React.useState(null);
  const [draggingSpace, setDraggingSpace] = React.useState(null);
  const [dropDestinationSpace, setDropDestinationSpace] = React.useState(null);

  // -------------- drag-and-drop action dialog ---------------
  const [showActionDialog, setShowActionDialog] = React.useState(false);

  // -------------- expansions for nested spaces ---------------
  const [expandedSpaces, setExpandedSpaces] = React.useState({});

  // -------------- Redux ---------------
  const token = useSelector((state) => state.token);
  const performGetSpaces = useSelector((state) => state.performGetSpaces);

  // --------------------------------------------
  //   EFFECTS
  // --------------------------------------------
  React.useEffect(() => {
    // Inform parent (if any) that the drawer open state changed
    onOpenChange(open);
  }, [open, onOpenChange]);

  React.useEffect(() => {
    onOpenChange(mobileOpen);
  }, [mobileOpen, onOpenChange]);

  React.useEffect(() => {
    if (isMobile) {
      setOpen(true); // Keep it open on mobile
    }
  }, [isMobile]);

  // Fetch spaces when token changes
  React.useEffect(() => {
    if (token) getSpaces();
  }, [token]);

  // Re-fetch spaces if Redux says so
  React.useEffect(() => {
    if (performGetSpaces) {
      getSpaces();
      dispatch({ type: "GET_SPACES", payload: { performGetSpaces: false } });
    }
  }, [performGetSpaces, dispatch]);

  // --------------------------------------------
  //   METHODS
  // --------------------------------------------
  const getSpaces = async () => {
    try {
      const [myRes, sharedRes] = await Promise.all([
        axios.get(`${API_URL}/spaces?shared=false`),
        axios.get(`${API_URL}/spaces?shared=true`),
      ]);
      setMySpaces(myRes.data.spaces || []);
      setSharedSpaces(sharedRes.data.spaces || []);
    } catch (err) {
      console.error(err);
    }
  };

  // Toggle drawer on mobile or set “minimize” on desktop
  const handleDrawerToggle = () => {
    if (isMobile) {
      setMobileOpen(!mobileOpen);
    } else {
      handleChangeSwitch();
    }
  };

  // Handles the "don't minimize" preference
  const handleChangeSwitch = () => {
    const newDontMinimize = !dontMinimize;
    setDontMinimize(newDontMinimize);
    localStorage.setItem("dontMinimize", newDontMinimize);
    setOpen(newDontMinimize);
  };

  // Toggle expansion for a space’s children
  const handleSpaceToggle = (e, spaceId) => {
    e.stopPropagation();
    setExpandedSpaces((prev) => ({
      ...prev,
      [spaceId]: !prev[spaceId],
    }));
  };

  // -------------- Drag & Drop finalize (merge vs. subspace) ---------------
  const handleActionChoice = async (choice) => {
    setShowActionDialog(false);
    if (!dropDestinationId || !draggingSpaceId) return;

    try {
      await axios.put(`${API_URL}/spaces/${dropDestinationId}`, {
        action: choice, // "merge" or "subspace"
        origin_space_id: draggingSpaceId,
      });
      // Refresh the spaces
      getSpaces();
    } catch (err) {
      console.error(err);
    } finally {
      setDraggingSpaceId(null);
      setDropDestinationId(null);
    }
  };

  // Clicking the settings icon to open collaborator dialog
  const handleCollaboratorsOpen = (e, space) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({
      type: "SET_COLLABORATORS_DIALOG_OPEN",
      payload: { collobartorsDialogOpen: true },
    });
  };

  // --------------------------------------------
  //   SIDEBAR ITEMS
  // --------------------------------------------
  const sidebarItemsTop = [
    {
      title: "My Reviews",
      icon: <StarOutline sx={{ fontSize: 20, color: "rgba(0, 0, 0, 0.6)" }} />, // you had StarOutline, example only
      path: "/my-reviews",
    },
    {
      title: "Community",
      icon: <GroupOutlinedIcon sx={{ fontSize: 20, color: "rgba(0, 0, 0, 0.6)" }} />, // you had GroupsOutlinedIcon
      path: "/community",
      children: [
        {
          icon: <StarOutline sx={{ fontSize: 20, color: "rgba(0, 0, 0, 0.6)" }} />, // you had StarOutline
          title: "Reviews",
          path: "/community",
        },
        {
          icon: <MenuIcon sx={{ fontSize: 20, color: "rgba(0, 0, 0, 0.6)" }} />, // you had SplitscreenOutlined
          title: "Collections",
          path: "/community-spaces",
        },
      ],
    },
  ];

  const sidebarItemsBottom = [
    // any bottom items if needed
  ];

  // --------------------------------------------
  //   RENDER
  // --------------------------------------------
  return (
    <>
      {/* Floating menu button */}
      <IconButton
        onClick={handleDrawerToggle}
        sx={{
          position: "fixed",
          top: 16,
          left: 16,
          zIndex: 2000,
          color: "#000",
          backgroundColor: "#fff",
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
          "&:hover": {
            backgroundColor: "#f0f0f0",
          },
        }}
      >
        <MenuIcon />
      </IconButton>

      {/* The Drawer itself (swipeable on mobile, permanent on desktop) */}
      <SidebarDrawer
        open={open}
        setOpen={setOpen}
        mobileOpen={mobileOpen}
        setMobileOpen={setMobileOpen}
        isMobile={isMobile}
        drawerWidth={drawerWidth}
      >
        {/* Inside the Drawer: Top items */}
        <SidebarTopItems
          open={open}
          items={sidebarItemsTop}
          location={location}
          navigate={navigate}
          isMobile={isMobile}
          setMobileOpen={setMobileOpen}
          setProfile={setProfile}
        />

        {/* My Collections collapsible */}
        <SidebarCollapsibleSection
          title="My Collections"
          open={open}
          icon={<MenuIcon sx={{ fontSize: 20, color: "rgba(0,0,0,0.6)" }} />}
          isSectionOpen={myCollectionsOpen}
          setIsSectionOpen={setMyCollectionsOpen}
        >
          <SpaceList
            spaces={mySpaces}
            open={open}
            location={location}
            navigate={navigate}
            isMobile={isMobile}
            setMobileOpen={setMobileOpen}
            setProfile={setProfile}
            expandedSpaces={expandedSpaces}
            handleSpaceToggle={handleSpaceToggle}
            searchQuery={searchQuery}
            draggingSpaceId={draggingSpaceId}
            setDraggingSpaceId={setDraggingSpaceId}
            draggingSpace={draggingSpace}
            setDraggingSpace={setDraggingSpace}
            dragInProcess={dragInProcess}
            setDragInProcess={setDragInProcess}
            dropDestinationId={dropDestinationId}
            setDropDestinationId={setDropDestinationId}
            dropDestinationSpace={dropDestinationSpace}
            setDropDestinationSpace={setDropDestinationSpace}
            setShowActionDialog={setShowActionDialog}
            handleCollaboratorsOpen={handleCollaboratorsOpen}
            setIsCreateSpaceOpen={setIsCreateSpaceOpen}
            setIsSubSpaceMode={setIsSubSpaceMode}
          />
        </SidebarCollapsibleSection>

        {/* Shared Collections collapsible */}
        <SidebarCollapsibleSection
          title="Shared Collections"
          open={open}
          icon={<ShareOutlinedIcon sx={{ fontSize: 20, color: "rgba(0,0,0,0.6)" }} />}
          isSectionOpen={sharedCollectionsOpen}
          setIsSectionOpen={setSharedCollectionsOpen}
        >
          <SpaceList
            disableDrag={true}
            spaces={sharedSpaces}
            open={open}
            location={location}
            navigate={navigate}
            isMobile={isMobile}
            setMobileOpen={setMobileOpen}
            setProfile={setProfile}
            expandedSpaces={expandedSpaces}
            handleSpaceToggle={handleSpaceToggle}
            searchQuery={searchQuery}
            draggingSpaceId={draggingSpaceId}
            setDraggingSpaceId={null}
            draggingSpace={draggingSpace}
            setDraggingSpace={setDraggingSpace}
            dragInProcess={dragInProcess}
            setDragInProcess={setDragInProcess}
            dropDestinationId={dropDestinationId}
            setDropDestinationId={setDropDestinationId}
            dropDestinationSpace={dropDestinationSpace}
            setDropDestinationSpace={setDropDestinationSpace}
            setShowActionDialog={setShowActionDialog}
            handleCollaboratorsOpen={handleCollaboratorsOpen}
            setIsCreateSpaceOpen={setIsCreateSpaceOpen}
            setIsSubSpaceMode={setIsSubSpaceMode}
          />
        </SidebarCollapsibleSection>

        {/* Bottom items if needed */}
        <SidebarTopItems
          open={open}
          items={sidebarItemsBottom}
          location={location}
          navigate={navigate}
          isMobile={isMobile}
          setMobileOpen={setMobileOpen}
          setProfile={setProfile}
        />
      </SidebarDrawer>

      {/* CreateSpace dialog */}
      {isCreateSpaceOpen && (
        <CreateSpace
          open={isCreateSpaceOpen}
          isSubSpaceMode={isSubSpaceMode}
          handleClose={() => setIsCreateSpaceOpen(false)}
          onSpaceAdded={() => {
            getSpaces();
            setIsCreateSpaceOpen(false);
          }}
        />
      )}

      {/* Drag & Drop merge/subspace choice */}
      <DragAndDropActionDialog
        open={showActionDialog}
        setOpen={setShowActionDialog}
        draggingSpace={draggingSpace}
        dropDestinationSpace={dropDestinationSpace}
        onActionChoice={handleActionChoice}
      />
    </>
  );
};

export default MiniSidebar;
