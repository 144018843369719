// SidebarCollapsibleSection.js

import React from "react";
import { ListItemButton, ListItemIcon, ListItemText, Collapse } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const SidebarCollapsibleSection = ({
  title,
  icon,
  open,
  isSectionOpen,
  setIsSectionOpen,
  children,
}) => {
  return (
    <>
      <ListItemButton
        onClick={() => setIsSectionOpen((prev) => !prev)}
        sx={{
          minHeight: 36,
          justifyContent: open ? "initial" : "center",
          px: 3,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: "20px",
            justifyContent: "center",
            marginRight: "10px",
          }}
        >
          {icon}
        </ListItemIcon>
        {open && (
          <>
            <ListItemText
              disableTypography
              primary={title}
              sx={{
                fontWeight: 300,
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth: "200px",
              }}
            />
            {isSectionOpen ? <ExpandLess /> : <ExpandMore />}
          </>
        )}
      </ListItemButton>

      <Collapse in={isSectionOpen && open} timeout="auto" unmountOnExit>
        {/* We pass in the children so you can render your search input and space list. */}
        {children}
      </Collapse>
    </>
  );
};

export default SidebarCollapsibleSection;
