import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from "react-redux";

import {
  EditOutlined,
  SaveOutlined,
  CancelOutlined,
} from '@mui/icons-material';
import {
  Dialog,
  Button,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import NameDescriptionSection from './NameDescriptionSection';
import KeywordsSection from './KeywordsSection';
import ImageUploaderAndCrop from './ImageUploaderAndCrop';
import CropDialog from './CropDialog'; // Adjust path as needed
import { StyledTextField } from './StyledTextField';

const SpaceDescription = ({ spaceData, onSave, onImageUpload, onVideoUpload, onRemoveImage }) => {
  console.log(spaceData)
  // ----------------------------
  // TEXT editing states
  // ----------------------------
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // 'sm' ~ 600px

  const [isEditing, setIsEditing] = useState(false);
  const [isEditingImage, setIsEditingImage] = useState(false);

  const [name, setName] = useState(spaceData?.name || 'Space Name');
  const [description, setDescription] = useState(
    spaceData?.description 
  );
  const [keywords, setKeywords] = useState(spaceData?.keywords || []);
  const [newKeyword, setNewKeyword] = useState('');
  const user = useSelector((state) => state.user);

  // ----------------------------
  // IMAGE states (drag & drop + staging)
  // ----------------------------
  const [isDragging, setIsDragging] = useState(false);
  const [stagedImage, setStagedImage] = useState(null);
  const [stagedImageFile, setStagedImageFile] = useState(null);

  // ----------------------------
  // CROP states
  // ----------------------------
  const [showCropModal, setShowCropModal] = useState(false);
  const [crop, setCrop] = useState({ unit: '%', width: 80, aspect: 16 / 9 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [upImg, setUpImg] = useState(null);
  const imgRef = useRef(null);
  console.log(user)
  // On spaceData changes, reset local states
  useEffect(() => {
    if (spaceData) {
      setName(spaceData.name);
      setDescription(spaceData.description);
      setKeywords(spaceData.keywords || []);
    }
  }, [spaceData]);

  // ----------------------------
  // NAME / DESCRIPTION HANDLERS
  // ----------------------------
  const handleEditClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsEditing(true);
  };

  const handleSaveClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onSave({ name, description, keywords });
    setIsEditing(false);
  };

  const handleCancelClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setName(spaceData?.name || 'Space Name');
    setDescription(spaceData?.description );
    setKeywords(spaceData?.keywords || []);
    setIsEditing(false);
  };

  // ----------------------------
  // KEYWORDS
  // ----------------------------
  const handleAddKeyword = () => {
    if (newKeyword && !keywords.includes(newKeyword)) {
      setKeywords([...keywords, newKeyword]);
      setNewKeyword('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddKeyword();
    }
  };

  // ----------------------------
  // IMAGE: FILE SELECT / DRAG-DROP
  // ----------------------------
  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    handleImageSelection(file);
  };

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    handleImageSelection(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleImageSelection = (file) => {
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setUpImg(e.target.result);
        setStagedImageFile(file);
        setShowCropModal(true);
      };
      reader.readAsDataURL(file);

      // Reset file input value to allow re-uploading the same file
      const fileInput = document.getElementById('imageUpload');
      if (fileInput) {
        fileInput.value = '';
      }
    }
  };
  // ----------------------------
  // CROP
  // ----------------------------
  const onCropComplete = (c) => {
    console.log('is editing 1')
    setIsEditingImage(true)
    setCompletedCrop(c);
  };

  const onImageLoad = (e) => {
    console.log('is editing 2')
    setIsEditingImage(true)
    imgRef.current = e.currentTarget;
  };

  const applyCrop = () => {
    console.log('Applying crop...');
    const image = imgRef.current;

    // If no crop is applied, use the full image
    if (!completedCrop) {
      console.log('No crop applied, using the full image.');
      const file = stagedImageFile;

      if (!file) {
        console.error('No image file available.');
        return;
      }

      // Generate a URL for the full image to display
      const fullImageUrl = URL.createObjectURL(file);

      // Update the displayed image
      setDisplayedImage(fullImageUrl);
      setShowCropModal(false);
      return;
    }

    if (!image) {
      console.error('Image reference is missing.');
      return;
    }

    // Proceed with cropping if `completedCrop` exists
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = completedCrop.width * scaleX;
    canvas.height = completedCrop.height * scaleY;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      image,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    );

    // Convert the canvas to a Blob (binary data)
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          console.error('Canvas to Blob conversion failed');
          return;
        }

        // Create a File object with the same properties as `stagedImageFile`
        const croppedFile = new File([blob], 'cropped-image.png', {
          type: 'image/png',
        });

        console.log('Cropped file:', croppedFile);

        // Generate a URL for the cropped image to display
        const croppedImageUrl = URL.createObjectURL(croppedFile);

        // Update the staged image and displayed image
        setStagedImageFile(croppedFile); // For upload
        setDisplayedImage(croppedImageUrl); // For preview
        setShowCropModal(false);
      },
      'image/png',
      1.0 // Quality (1.0 for best quality)
    );
  };

  const handleCancelImage = () => {
    setIsEditingImage(false)
    // Clear our staging states
    setDisplayedImage(null)
    setStagedImageFile(null);
    setStagedImage(null);
    setUpImg(null);
    setShowCropModal(false);

    // Also clear the file input’s value
    const fileInput = document.getElementById('imageUpload');
    if (fileInput) {
      fileInput.value = '';
    }
  };

  const handleSaveImage = () => {
    setIsEditingImage(false)
    if (stagedImageFile) {

      onImageUpload(stagedImageFile);

    }
  };
  const handleSaveVideo = (videoUrl) => {
    onVideoUpload(videoUrl);

  };

  const handleRemoveImage = (videoUrl) => {
    onRemoveImage(videoUrl);

  };

  const handleCropAgain = () => {
    const displayed = stagedImage || spaceData?.image;
    if (displayed) {
      setUpImg(displayed);
      setCrop({ unit: '%', width: 80, aspect: 16 / 9 });
      setCompletedCrop(null);
      setShowCropModal(true);
    }
  };

  // The final image for the UI
  const [displayedImage, setDisplayedImage] = useState(stagedImage || spaceData?.image);
  useEffect(() => {
    setDisplayedImage(spaceData?.image)
  }, [stagedImage, spaceData])
  const videoUrl = spaceData?.video_url;

  return (
    <div
      style={{
        width: '100%',
        background:
          'linear-gradient(180deg, rgba(25, 118, 210, 0.08) 0%, rgba(255, 255, 255, 0) 100%)',
        padding: '12px',
        borderRadius: '8px',
      }}
    >
      <div style={{
        maxWidth: '1200px', margin: '0 auto', padding: '12px',
      }}>
        <div
          style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            marginBottom: '24px',
          }}
        >
          {/* Left: Name & Description */}
          <div
            style={{
              flex: 2,
              marginRight: isMobile ? '0' : '32px',
              marginBottom: isMobile ? '16px' : '0',
            }}
          >
            <NameDescriptionSection
              disabled={!(user && (spaceData.user_id === user.id))}
              isEditing={isEditing}
              name={name}
              setName={setName}
              description={description}
              setDescription={setDescription}
              handleCancelClick={handleCancelClick}
              handleSaveClick={handleSaveClick}
              handleEditClick={handleEditClick}
              StyledTextField={StyledTextField}
            />
          </div>

          {/* Middle: Keywords */}
          <div
            style={{
              flex: 1,
              marginRight: isMobile ? '0' : '32px',
              marginBottom: isMobile ? '16px' : '0',
            }}
          >
            <KeywordsSection
              isEditing={isEditing}
              keywords={keywords}
              setKeywords={setKeywords}
              newKeyword={newKeyword}
              setNewKeyword={setNewKeyword}
              handleAddKeyword={handleAddKeyword}
              handleKeyDown={handleKeyDown}
              StyledTextField={StyledTextField}
            />
          </div>

          {/* Right: Image */}
          <div
            style={{
              width: isMobile ? '100%' : '256px',
              flexShrink: 0,
              marginTop: isMobile ? '16px' : '0',
            }}
          >
            <ImageUploaderAndCrop
              isEditing={isEditingImage}
              disabled={!(user && (spaceData.user_id === user.id))}
              isDragging={isDragging}
              handleDrop={handleDrop}
              handleDragOver={handleDragOver}
              handleDragLeave={handleDragLeave}
              displayedImage={displayedImage}
              defaultVideoUrl={spaceData.video_url}
              stagedImage={stagedImage}
              stagedImageFile={stagedImageFile}
              handleCancelImage={handleCancelImage}
              handleSaveImage={handleSaveImage}
              onVideoSave={handleSaveVideo}
              onRemoveImage={handleRemoveImage}
              handleCropAgain={handleCropAgain}
              handleFileInput={handleFileInput}
            />
          </div>
        </div>
      </div>

      {/* Crop Dialog */}
      <CropDialog
        open={showCropModal}
        handleCancelImage={handleCancelImage}
        applyCrop={applyCrop}
        upImg={upImg}
        crop={crop}
        setCrop={setCrop}
        onCropComplete={onCropComplete}
        imgRef={imgRef}
        imageUrl={displayedImage}
        onImageLoad={onImageLoad}
      />
    </div>
  );

};

export default SpaceDescription;
