import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import ChatIcon from "@mui/icons-material/Chat";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import {
  Typography,
  Tooltip,
  List,
  ListItem,
  ListItemButton,
  Divider,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { API_URL, popupTimer } from "../../consts";
import { useDispatch } from "react-redux";

// Example API utility (fetch or axios)
async function getAllChats() {
  const url = `${API_URL}/chats`;

  const response = await axios.get(url);
  if (response.status !== 200) {
    throw new Error("Failed to fetch chats");
  }
  return response.data;
}

async function getChatById(chatId) {
  console.log('chatid', chatId)
  const url = `${API_URL}/chats/` + chatId;

  const response = await axios.get(url);
  console.log(response)
  if (response.status !== 200) {
    throw new Error("Failed to fetch chat details");
  }
  return response.data;
}

async function postAIRequest(payload) {
  const url = `${API_URL}/chats/ai`;

  const response = await axios.post(url, payload);
  if (response.status != 200) {
    throw new Error("Failed to post AI request");
  }
  console.log('resp', response)
  return response.data
}

const ChatComponent = () => {
  // --------------------------------------------------
  // States
  // --------------------------------------------------
  const dispatch = useDispatch();

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [userChats, setUserChats] = useState([]);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [isCommunity, setIsCommunity] = useState(false);

  // --------------------------------------------------
  // Load the user's chats on mount
  // --------------------------------------------------
  useEffect(() => {
    setIsCommunity(window.location.href.includes("community"));
  }, []);

  useEffect(() => {
    if(selectedChatId){
      console.log('load')
      console.log('selected from use',selectedChatId)
      loadChat(selectedChatId);
    }

  }, [selectedChatId]);
  useEffect(() => {
    if (isChatOpen) {
      loadUserChats();
    }
  }, [isChatOpen]);
  async function loadUserChats() {
    try {
      const data = await getAllChats();
      // data is expected to be { chats: [...] }
      // each chat might look like { _id: "...", name: "..." }
      // Transform them to { chat_id, chat_name } for local use
      console.log(data)
      if (data?.chats) {
        const transformed = data.chats.map((c) => ({
          id: c.id,
          name: c.name,
        }));
        setUserChats(transformed);
      }
    } catch (error) {
      console.error("Error loading chats:", error);
    }
  }

  // --------------------------------------------------
  // Load a single chat's data
  // --------------------------------------------------
  async function loadChat(chatId) {
    try {
      const data = await getChatById(chatId);
      // data might be { chats: [ { _id, name, chat_items: [...] } ] }
      // Usually you'd expect exactly one chat in data.chats[0]
      const chat = data?.chats?.[0];
      if (!chat) return;

      const chatItems = chat.chat_items || [];
      const transformedMessages = chatItems.map((item) => ({
        user: item.role === "system" ? "system" : "You",
        text: item.content,
        date: item.create_time,
      }));
      setMessages(transformedMessages);
    } catch (error) {
      console.error("Error loading chat:", error);
    }
  }
  function showErrorPopup(msg) {
    dispatch({
      type: "SET_POPUP",
      payload: {
        text: msg?.toString() || "Error occurred",
        type: "error",
      },
    });
    setTimeout(() => {
      dispatch({
        type: "SET_POPUP",
        payload: { text: "", type: "" },
      });
    }, popupTimer);
  }

  // --------------------------------------------------
  // Handle selecting a chat
  // --------------------------------------------------
  const handleSelectChat = (chatId) => {
    setSelectedChatId(chatId);

  };

  // --------------------------------------------------
  // Create a new empty chat by calling POST /ai
  // with no chat_id, minimal data
  // --------------------------------------------------
  const handleAddNewChat = () => {
    console.log('add new chat')
    // Create a local "temp" chat with a pseudo-ID
    const tempId = `temp-${Date.now()}`;

    // Add the new chat to the user's chat list
    setUserChats((prevChats) => [
      ...prevChats,
      { id: tempId, name: "New Chat" }
    ]);

    // Select this new chat and clear the message history
    setSelectedChatId(tempId);
    setMessages([]);
  };

  // --------------------------------------------------
  // Handle sending a message
  // --------------------------------------------------
  const handleSendMessage = async () => {
    // 1. Basic checks
    if (!inputValue.trim()) return;
  
    // 2. Add the user’s message locally
    const userMsg = {
      user: "You",
      text: inputValue.trim(),
      date: new Date().toLocaleString(),
    };
    setMessages((prev) => [...prev, userMsg]);
    setInputValue("");
    setIsTyping(true);
  
    try {
      // 3. If there's no selected chat yet, we treat this as a new chat creation
      let chatIdToSend = selectedChatId || null;
  
      // 4. Figure out the `messageType` and `spaceId` (your existing logic)
      let isSpacePage = window.location.href.includes("/spaces/");
      let spaceId = isSpacePage
        ? window.location.href.split("/").pop()
        : "";
      let messageType = isSpacePage
        ? "space"
        : isCommunity
        ? "community"
        : "my_reviews";
  
      // 5. Call your API
      const payload = {
        chat_id: chatIdToSend,
        command: userMsg.text,
        message_type: messageType,
        space_id: spaceId,
        mode: "chat",
      };
  
      const resp = await postAIRequest(payload);
      setIsTyping(false);
  
      // 6. If the server gave us a new chat (e.g. first message), use that chat_id
      const newChatId = resp?.chat_id;
      const chatName = resp?.name || "New Chat";
      console.log('selected',selectedChatId)

      console.log('resp',resp)
      console.log('new',newChatId)
      if ((!selectedChatId ||selectedChatId.startsWith('temp')) && newChatId) {
        // We had no chat selected, so this is a truly “new” chat
        setSelectedChatId(newChatId);
        setUserChats((prev) => [...prev, { id: newChatId, name: chatName }]);
      }
  
      // 7. Show the AI/system response
      const aiResponse = resp.summary || "No response.";
      const systemMsg = {
        user: "system",
        text: aiResponse,
        date: new Date().toLocaleString(),
      };
      setMessages((prev) => [...prev, systemMsg]);
  
      // 8. Handle rates, if any
      if (resp.rates?.length > 0) {
        dispatch({ type: "FILTER_RATES", payload: resp.rates });
      }
  
    } catch (err) {
      setIsTyping(false);
      console.error("Error sending message:", err);
      showErrorPopup(
        err.response?.data?.error ||
          "Something went wrong. Please try again later."
      );
    }
  };
  

  return (
    <>
      {/* The Chat Widget */}
      {isChatOpen && (
        <Paper
          elevation={6}
          sx={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            width: "500px",
            height: "500px",
            display: "flex",
            flexDirection: "column",
            borderRadius: "12px",
            boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
            overflow: "hidden",
          }}
        >
{/* Header */}
<Box
  sx={{
    backgroundColor: "#00796b",
    color: "white",
    padding: "15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }}
>
  <Box 
    sx={{
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "10px"
    }}
  >
    <Typography variant="h6" sx={{ fontWeight: 600 }}>
      Personal GPT
    </Typography>
    <CloseIcon
      onClick={() => setIsChatOpen(false)}
      sx={{ cursor: "pointer", fontSize: "1.5rem" }}
    />
  </Box>
  <Typography 
    variant="caption" 
    sx={{ 
      fontSize: "0.7rem", 
      textAlign: "center", 
      opacity: 0.8,
      padding: "0 10px"
    }}
  >
    Information may not be 100% reliable. Always verify.
  </Typography>
</Box>

          {/* Chat Body */}
          <Box sx={{ display: "flex", flexGrow: 1, overflow: "hidden" }}>
            {/* Side Panel: List of user chats */}
            <Box
              sx={{
                width: "30%",
                borderRight: "1px solid #ccc",
                display: "flex",
                flexDirection: "column",
                position: "relative"
              }}
            >
              <List sx={{ flexGrow: 1, overflowY: "auto", paddingBottom: "56px" }}>
                {userChats.map((chat) => (
                  <ListItem key={chat.id} disablePadding onClick={() => handleSelectChat(chat.id)}>
                    <ListItemButton
                      selected={chat.id === selectedChatId}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: "#a5d6a7",
                          "&:hover": { backgroundColor: "#94cfa3" },
                        },
                      }}
                    >
                      <Tooltip title={chat.name} placement="right">
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 500,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "100%",
                            "&:hover": { whiteSpace: "normal" },
                          }}
                        >
                          {chat.name}
                        </Typography>
                      </Tooltip>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
              <Divider />

              {/* Add Chat Button */}
              <Box sx={{ position: "absolute", bottom: 8, left: "50%", transform: "translateX(-50%)", zIndex: 10999, backgroundColor: "white", borderRadius: "50%" }}>
                <Tooltip title="Start a new chat">

                  <Fab
                    size="small"
                    color="primary"
                    disabled={selectedChatId?.startsWith("temp-")}
                    onClick={handleAddNewChat}
                    sx={{
                      boxShadow: "none",
                      backgroundColor: "#00796b",
                      "&:hover": { backgroundColor: "#005f56" },
                    }}
                  >
                    <AddIcon />
                  </Fab>
                </Tooltip>
              </Box>
            </Box>



            {/* Main Chat Area */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                width: '70%',
                height: "100%",
              }}
            >
              {/* Messages Section - FIXED SCROLL */}
              <Box
                sx={{
                  flexGrow: 1,
                  padding: "15px",
                  overflowY: "auto",
                  backgroundColor: "#f5f5f5",
                }}
              >
                {messages.length > 0 ? (
                  messages.map((msg, index) => (
                    <Box
                      key={index}
                      sx={{
                        margin: "10px 0",
                        display: "flex",
                        justifyContent: msg.user === "You" ? "flex-end" : "flex-start",
                      }}
                    >
                      <Tooltip title={msg.date || ""} arrow placement="top">
                        <Typography
                          variant="body2"
                          sx={{
                            padding: "10px 15px",
                            borderRadius: "12px",
                            backgroundColor: msg.user === "You" ? "#00796b" : "#e0e0e0",
                            color: msg.user === "You" ? "white" : "black",
                            maxWidth: "70%",
                            wordWrap: "break-word",
                            cursor: "default",
                          }}
                        >
                          {msg.text}
                        </Typography>
                      </Tooltip>
                    </Box>
                  ))
                ) : (
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: "center",
                      color: "#757575",
                      marginTop: "10px",
                    }}
                  >
                    No messages yet.
                  </Typography>
                )}
                {isTyping && (
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: "left",
                      color: "#aaa",
                      fontStyle: "italic",
                      marginTop: "10px",
                    }}
                  >
                    Processing...
                  </Typography>
                )}
              </Box>

              {/* Chat Input - FIXED AT BOTTOM */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px",
                  borderTop: "1px solid #ccc",
                  backgroundColor: "white",
                  minHeight: "60px", // ✅ Fix for input visibility
                }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder="Type your message..."
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") handleSendMessage();
                  }}
                  sx={{
                    marginRight: "10px",
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                    },
                  }}
                />
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#00796b",
                    "&:hover": { backgroundColor: "#005f56" },
                    fontWeight: 600,
                    textTransform: "none",
                    borderRadius: "8px",
                  }}
                  onClick={handleSendMessage}
                >
                  Send
                </Button>
              </Box>
            </Box>
          </Box>
        </Paper>


      )}

      {/* Floating Button to open/close chat (hide if in community) */}
      {!isChatOpen && !window.location.href.includes("community") && (
        <Fab
          color="primary"
          onClick={() => setIsChatOpen(true)}
          sx={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            color: "#5a5a5a",
            backgroundColor: "#c6fbe8",
            "&:hover": { backgroundColor: "#1dd5da" },
            boxShadow: "0 4px 8px rgba(0,0,0,0.3)",
          }}
        >
          <ChatIcon />
        </Fab>
      )}
    </>
  );
};

export default ChatComponent;
