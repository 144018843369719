
export const API_URL = "https://webapi.datarate.io"; // export const RESULTS_PER_PAGE = 12;
// export const API_URL = "http://localhost:8081"; // export const RESULTS_PER_PAGE = 12;
// export const API_URL = 'https://d1lvk5kcdj.execute-api.eu-central-1.amazonaws.com/datarate';// export const RESULTS_PER_PAGE = 12;

export const popupTimer = 3000;


export const validateWebsite = (item) => {
  if (
    /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(
      item
    )
  ) {
    return item.includes('https://') ||item.includes('http://');

  } else if (
    /^((https?:\/\/)|(www.))(?:([a-zA-Z]+)|(\d+\.\d+.\d+.\d+)):\d{0,5}$/.test(
      item
    )
  ) {
    return item.includes('https://') ||item.includes('http://');
  } else {
    return false;
  }
};
